import React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';

const { Footer } = Layout;

const RootElement = styled(Footer)`
  justify-content: center;
  text-align: center;
  width: 100%;
  font-size: .75rem;
`;

const ShortenNewButton = () => {
  return <RootElement>Rakennettu rakkaudella <span aria-label="heart" role="img">💜</span> <a target="_blank" rel="noopener noreferrer" href="https://arto.dev">Arto Liukkonen</a> & <a target="_blank" rel="noopener noreferrer" href="https://www.eijajokilahti.com">Eija Jokilahti</a></RootElement>
}

export default ShortenNewButton;