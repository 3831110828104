import React from 'react';
import styled from 'styled-components';

const RootElement = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #430921;
  color: #f2f2f2;
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
  cursor: pointer;
  img {
    width: 100px;
    padding: 4rem 0 2rem;
  }
`;

const ShortenNewButton = () => {
  return <RootElement onClick={() => document.getElementById("root").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })}> Lyhennä toinen osoite ^</RootElement>
}

export default ShortenNewButton;