import React, { useState } from "react";
import styled from "styled-components";
import { Tooltip, Spin, Input, Row, Col, Form, Button, Layout } from "antd";
import axios from "axios";
import QRCode from "qrcode.react";
import copy from "./assets/copy.svg";
import Menu from "./components/Menu";
import Logo from "./components/Logo";
import ContentSpacer from "./components/ContentSpacer";
import ShortenNewButton from "./components/ShortenNewButton";
import Contact from "./components/Contact";
import "./App.css";

const { Content } = Layout;

const APP_URL = `${
  process.env.NODE_ENV === "development"
    ? "https://api.beta.51.fi"
    : `https://api.${window.location.host.replace("www.51.fi", "51.fi")}`
}/shorten`;
const CLIPBOARD_ACCESS =
  navigator.clipboard && typeof navigator.clipboard.writeText === "function";

const RootContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  transition: all 0.5s ease-out;
  display: flex;
  flex-direction: column;
`;

const BgContainer = styled.div`
  background: linear-gradient(
    136deg,
    #941242,
    #601294,
    #941289,
    #d51389,
    #0066dd,
    #00ddcb
  );
  background-size: 1200% 1200%;

  animation: AnimationName 120s ease infinite;

  @-webkit-keyframes AnimationName {
    0% {
      background-position: 7% 0%;
    }
    50% {
      background-position: 94% 100%;
    }
    100% {
      background-position: 7% 0%;
    }
  }
  @-moz-keyframes AnimationName {
    0% {
      background-position: 7% 0%;
    }
    50% {
      background-position: 94% 100%;
    }
    100% {
      background-position: 7% 0%;
    }
  }
  @-o-keyframes AnimationName {
    0% {
      background-position: 7% 0%;
    }
    50% {
      background-position: 94% 100%;
    }
    100% {
      background-position: 7% 0%;
    }
  }
  @keyframes AnimationName {
    0% {
      background-position: 7% 0%;
    }
    50% {
      background-position: 94% 100%;
    }
    100% {
      background-position: 7% 0%;
    }
  }
`;

const FormContent = styled.div`
  grid-area: content;
  color: #f7f7f7;
  justify-content: start;
  align-items: center;
  display: flex;
  flex-direction: column;

  .error {
    color: #f7f7f7;
    margin-top: -1rem;
    padding-bottom: 1rem;
  }
`;

const Response = styled.div`
  color: #222;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;

  .error {
    font-size: 1rem;
    margin-top: -1rem;
    padding-bottom: 1rem;
  }

  img {
    vertical-align: middle;
    cursor: pointer;
    margin-left: 1rem;
    &:active {
      background-color: gray;
    }
    transition: all 0.1s linear;
  }

  p {
    margin-top: 0;
  }
`;

const ResponseCols = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ResponseLeft = styled.div`
  padding: 1rem;
`;
const ResponseRight = styled.div`
  padding: 1rem;
`;
let lastUrl = "";

const submitURL = async (value, setResult, setLoading, FB) => {
  try {
    const url = value.trim();
    if (url === lastUrl) {
      return;
    }
    setLoading("url");
    const res = await axios({
      method: "post",
      url: APP_URL,
      data: {
        url,
        accessToken: FB && FB.authResponse.accessToken,
      },
    });
    lastUrl = url;

    setResult(res.data);
    setLoading(false);
    document
      .getElementById("response")
      .scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  } catch (e) {
    setResult(e.response.data);
    setLoading(false);
  }
  return;
};

const customURL = async (value, original, setResult, setLoading, FB) => {
  try {
    const url = value.trim();
    setLoading("custom");
    const res = await axios({
      method: "post",
      url: APP_URL,
      data: {
        url: lastUrl,
        slug: url,
        accessToken: FB && FB.authResponse.accessToken,
      },
    });
    setResult({ ...res.data, shortcode: res.data.shortcode });
    setLoading(false);
  } catch (e) {
    console.log(e.response.data.errorMessage);
    setResult({
      shortcode: original,
      customError: e.response.data.errorMessage,
    });
    setLoading(false);
  }

  return;
};

const copyToClipboard = (str) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(str);
  }
};

const App = () => {
  const [result, setResult] = useState();
  const [FB, setFB] = useState(null);
  const [loading, setLoading] = useState(null);
  const [showCustom, setShowCustom] = useState(null);
  const [urlForm] = Form.useForm();
  const [customForm] = Form.useForm();

  console.log(result);

  return (
    <RootContainer>
      <Content>
        <BgContainer>
          <Row>
            <Col xs={{ span: 20, offset: 2 }} sm={{ span: 12, offset: 6 }}>
              <Menu FB={FB} setFB={setFB} />
            </Col>
          </Row>

          <Logo />
          <FormContent>
            <Row
              style={{
                width: "100%",
                paddingTop: "1.5rem",
                textAlign: "center",
              }}
            >
              <Col
                xs={{ span: 16, offset: 4 }}
                sm={{ span: 12, offset: 6 }}
                lg={{ span: 6, offset: 9 }}
              >
                {loading === "url" ? (
                  <Spin size="large" />
                ) : (
                  <Form
                    form={urlForm}
                    layout="vertical"
                    onFinish={(value) => {
                      setShowCustom(false);
                      submitURL(value.url, setResult, setLoading, FB);
                    }}
                    noValidate
                  >
                    <Form.Item
                      label="Syötä osoite jonka haluat lyhentää"
                      name="url"
                    >
                      <Input
                        placeholder="https://example.com"
                        type="url"
                        autoComplete="off"
                      />
                    </Form.Item>
                    {result && result.errorMessage && (
                      <div className="error">{result.errorMessage}</div>
                    )}
                    <Form.Item>
                      <Button
                        type="primary"
                        style={{ backgroundColor: "#e7156b" }}
                        htmlType="submit"
                      >
                        Lyhennä
                      </Button>
                    </Form.Item>
                  </Form>
                )}
              </Col>
            </Row>
            <ContentSpacer height={20} color="#430921" />
          </FormContent>
        </BgContainer>

        {result && result.shortcode && (
          <Response id="response">
            <ResponseCols>
              <ResponseLeft>
                <h2>
                  Lyhennetty
                  <br />
                  osoite on
                </h2>
                <p>
                  {!CLIPBOARD_ACCESS && (
                    <span
                      onClick={() =>
                        copyToClipboard(
                          `https://${window.location.host}/${result.shortcode}`
                        )
                      }
                    >
                      {`https://${window.location.host}/${result.shortcode}`}
                    </span>
                  )}
                  {CLIPBOARD_ACCESS && (
                    <Tooltip
                      placement="top"
                      title={"Kopioi leikepöydälle klikkaamalla"}
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          copyToClipboard(
                            `https://${window.location.host}/${result.shortcode}`
                          )
                        }
                      >
                        {`https://${window.location.host}/${result.shortcode}`}
                      </span>

                      <img
                        onClick={() =>
                          copyToClipboard(
                            `https://${window.location.host}/${result.shortcode}`
                          )
                        }
                        alt="copy"
                        src={copy}
                        style={{ height: "1rem" }}
                      />
                    </Tooltip>
                  )}
                </p>

                {!showCustom && (
                  <p>
                    <Button
                      onClick={() => setShowCustom(true)}
                      style={{ color: "#f2f2f2" }}
                    >
                      Anna oma pääte
                    </Button>
                  </p>
                )}

                {showCustom && (
                  <Row
                    style={{
                      width: "100%",
                      paddingBottom: "1.5rem",
                      textAlign: "center",
                    }}
                  >
                    <Col
                      xs={{ span: 16, offset: 4 }}
                      sm={{ span: 12, offset: 6 }}
                    >
                      {loading === "custom" ? (
                        <Spin size="large" />
                      ) : (
                        <Form
                          form={customForm}
                          layout="vertical"
                          onFinish={(value) =>
                            customURL(
                              value.custom,
                              result.shortcode,
                              setResult,
                              setLoading,
                              FB
                            )
                          }
                        >
                          <Form.Item name="custom">
                            <Input
                              placeholder={result.shortcode}
                              required
                              autoComplete="off"
                            />
                          </Form.Item>
                          <div className="error">{result.customError}</div>
                          <Form.Item>
                            <Button type="primary" htmlType="submit">
                              Tallenna
                            </Button>
                          </Form.Item>
                        </Form>
                      )}
                    </Col>
                  </Row>
                )}
              </ResponseLeft>

              <ResponseRight>
                <p>
                  <QRCode
                    size={200}
                    value={`https://${window.location.host}/${result.shortcode}`}
                    style={{ border: ".5rem solid white" }}
                  />
                </p>
              </ResponseRight>
            </ResponseCols>

            <p>
              <small>alkuperäinen osoite: {lastUrl}</small>
            </p>
            <ShortenNewButton />
          </Response>
        )}
      </Content>
      <Contact />
    </RootContainer>
  );
};

export default App;
