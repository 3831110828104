import React from 'react';
import styled from 'styled-components';
import logo from '../assets/logo.svg';

const RootElement = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  img {
    width: 125px;
    padding: 5rem 0 2rem;
  }
`;

const Logo = () => {
  return <RootElement><img alt="51.fi" src={logo} /></RootElement>
}

export default Logo;