import React from 'react';

const ContentSpacer = ({ height }) => {
  return <svg width='100%' height='100%' viewBox="0 0 100 100" preserveAspectRatio="none">
    <defs>
      <linearGradient id="sampleGradient">
        <stop offset="0%" stop-color="rgb(148,18,66)" />
        <stop offset="100%" stop-color="rgb(20,4,11)" />
      </linearGradient>
    </defs>

    <polygon points={`0,100 100,0 100,${height}`} fill="url(#sampleGradient)" />
    <polygon points={`0,100 100,100 100,${height}`} style={{ fill: "white" }} />
  </svg>
}

export default ContentSpacer;

