import React from "react";
import styled from "styled-components";
// import FBLoginButton from "./FBLoginButton";
// import fb from "../assets/fb.svg";

const RootElement = styled.div`
  float: right;
  padding: 1rem;
  img {
    padding-right: 0.5rem;
  }
`;

const Menu = ({ FB, setFB }) => {
  return (
    <RootElement>
      {/* {!FB && (
        <span style={{ cursor: "pointer" }}>
          <FBLoginButton onLogin={(loggedIn, data) => loggedIn && setFB(data)}>
            <img src={fb} alt="FB" style={{ height: "1rem" }} />{" "}
            <span style={{ color: "#FEFEFE" }}>Kirjaudu</span>
          </FBLoginButton>
        </span>
      )}
      {FB && (
        <span style={{ color: "#FEFEFE" }}>
          Kirjautunut käyttäjänä {FB.user.name}
        </span>
      )} */}
    </RootElement>
  );
};

export default Menu;
